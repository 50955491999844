/**
 * Masonry Grid with lazy loading
 * @dependencies isotope-layout
 * @dependencies imagesloaded
 * @dependencies scrollmonitor
 */

import Masonry from 'masonry-layout'
import lozad from 'lozad'

const grid = document.querySelector('.js-masonry');
if (grid) {

  // General Layout
  // We init the masonry instance but we dont fire it yet
  const masonry = new Masonry( grid, {
    columnWidth: '.js-grid-item',
    gutter: '.js-gutter-sizer',
    itemSelector: '.js-grid-item',
    percentPosition: true,
    resize: true,
    stagger: 30
  })

  // Masonry on Products (Lazy Load)
  const items = grid.querySelectorAll('.js-grid-item');
  for (const item of items) {

    // Some preparation
    const img = item.querySelector('img');
    const ratio = img.dataset.ratio;
    img.parentNode.style.paddingBottom = ratio * 100 + '%';
    masonry.layout();

    // Lazy Loading with Lozad
    const observer = lozad(img, {
      threshold: 0.3, // ratio of element convergence
      loaded: function (el) {
        // When Loaded
        el.closest('.js-grid-item').classList.add('is-loaded');
        masonry.layout();
      }
    })
    observer.observe();
  }
}
