window.addEventListener('load', function() {
  var checklist = document.querySelector('.js-checklist');

  if(!checklist)
    return;

  var tasks    = document.querySelectorAll('.js-task');
  var subtasks = document.querySelectorAll('.js-subtask');
  var checkedTasksIds = get_checked_tasks_ids();
  var submitButton = document.querySelector('.js-submit');
  var changesDoneMessage = document.querySelector('.js-changes_done_message');
  var xhr;
  var loadPostsAjaxUrl;

  // On task input change, change all subtasks in the same way (checked/unchecked)
  tasks.forEach((task) => {
    var task_input = task.querySelector('.js-task_input');
    task_input.addEventListener( 'change', function() {
      var task_subtasks = task.querySelectorAll('.js-subtask');
      if(!task_subtasks)
        return;

      task_subtasks.forEach((task_subtask) => {
        var task_subtask_input = task_subtask.querySelector('.js-subtask_input');
        task_subtask_input.checked = this.checked;
      });

      maybe_edit_button_ability();

    });
  });

  // On subtask input change, (un)check parent task input if its subtasks are all checked or non
  subtasks.forEach((subtask) => {
    var subtask_input = subtask.querySelector('.js-subtask_input');
    subtask_input.addEventListener( 'change', function() {

      var siblings_subtasks = document.querySelectorAll("[data-parent='"+subtask_input.dataset.parent+"']");
      if(!siblings_subtasks)
        return;

      var are_all_siblings_checked = true;
      siblings_subtasks.forEach((siblings_subtask) => {
        if (!siblings_subtask.checked) {
          are_all_siblings_checked = false;
        }
      });

      var task_input = document.querySelector("[data-id='"+subtask_input.dataset.parent+"']");
      task_input.checked = are_all_siblings_checked;

      maybe_edit_button_ability();

    });
  });

  // On submit button, update User's checked tasks & display confirmation message
  submitButton.addEventListener("click", function(){

    var currentCheckedTasksIds = get_checked_tasks_ids();

    // Ajax Request
    xhr = new XMLHttpRequest();
    loadPostsAjaxUrl = ajaxurl.ajaxurl
      + '?action=update_user_tasks'
      + '&checklist_id=' + checklist.dataset.id
      + '&tasks_ids=' + currentCheckedTasksIds;
    xhr.open('GET', loadPostsAjaxUrl);
    xhr.onload = function() {
      if (xhr.status === 200) {

        // Disable submit button & Display confirmation message
        submitButton.disabled = true;
        changesDoneMessage.classList.remove("visually-hidden");

        // Set new User's checked Tasks
        checkedTasksIds = get_checked_tasks_ids();
      }
    };
    xhr.send();
  });

  // Return an array of all checked tasks id
  function get_checked_tasks_ids(){

    var checkedTasks = document.querySelectorAll('input[type=checkbox]:checked');

    var currentCheckedTasksIds = [];
    checkedTasks.forEach((checkedTask) => {
      currentCheckedTasksIds.push(checkedTask.dataset.id)
    });

    return currentCheckedTasksIds;

  }

  // Enable submit button if Tasks have been (un)checked since page is loaded, or Disable
  function maybe_edit_button_ability(){

    var currentCheckedTasksIds = get_checked_tasks_ids();

    submitButton.disabled = (JSON.stringify(checkedTasksIds) === JSON.stringify(currentCheckedTasksIds));

  }

});
