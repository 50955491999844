import lozad from 'lozad'

window.addEventListener('load', function() {

  var postsList = document.querySelector('.js-posts-list');

  if(!postsList)
    return;

  window.paged = 1;
  var post_type = postsList.dataset.type;
  var page_slug = postsList.dataset.slug;
  var basename = location.origin + '/' + page_slug + '/';
  var maxnumpages = (document.querySelector('.js-posts-list-item')) ?
    document.querySelector('.js-posts-list-item').getAttribute('data-maxnumpages') : 0;
  var submitButton = document.querySelector('.js-filter-submit');
  var loadMoreButton = document.querySelector('.js-load-more');
  var filters = [];
  var search = document.querySelector('.js-search-filters .js-search');
  var selects = document.querySelectorAll('.js-select');
  selects.forEach((select) => {
    filters.push([
      {
        slug : select.dataset.slug,
        userslug : select.dataset.userslug
      }
    ]);
  });
  const observer = lozad();
  var xhr;
  var loadPostsAjaxUrl;

  // Reload Posts on submitButton click
  if(submitButton){
    submitButton.addEventListener('click', function(){
      reloadPosts();
    });
  }

  // Reload Posts on search input enter
  if (search) {
    search.addEventListener("keyup", function(event) {
      if (event.keyCode === 13) {
        reloadPosts();
      }
    });
  }

  // On Load More Click, increment paged & load Courses
  loadMoreButton.addEventListener('click', function(){

    // Disabled during ajax load
    if(loadMoreButton.classList.contains('visually-hidden'))
      return;

    window.paged++;

    loadPosts();
  });

  // Empty List & load a new List
  function reloadPosts(){

    // Disabled during ajax load
    if(postsList.classList.contains('loading'))
      return;

    postsList.innerHTML = '';
    loadMoreButton.classList.add('visually-hidden');
    window.paged = 1;
    rewriteURL();
    loadPosts();

  }

  // Show previous Page & Load hidden next Page with filters
  function loadPosts(){

    // Disable filterSubmit & loadMoreButton during ajax load
    loadMoreButton.classList.add('visually-hidden');
    postsList.classList.add('loading');

    // Ajax Request
    xhr = new XMLHttpRequest();
    loadPostsAjaxUrl = ajaxurl.ajaxurl
      + '?action=get_posts'
      + '&post_type=' + post_type
      + '&paged=' + window.paged;
    for (const select of selects) {
      loadPostsAjaxUrl += '&' + select.dataset.slug + '=' + select.value;
    }
    if (search && search.value != ''){
      loadPostsAjaxUrl += '&search=' + search.value;
    }
    xhr.open('GET', loadPostsAjaxUrl);
    xhr.onload = function() {
      if (xhr.status === 200) {

        // Add page results to list
        postsList.innerHTML += xhr.responseText;

        // Lazy Load Observer
        observer.observe();

        // On 1st page, set maxnumpage
        if(window.paged == 1)
          maxnumpages = (document.querySelector('.js-posts-list-item')) ?
            document.querySelector('.js-posts-list-item').getAttribute('data-maxnumpages') : 0;

        // Show LoadMoreButton if more Pages
        if(window.paged < maxnumpages)
          loadMoreButton.classList.remove('visually-hidden');

        // Remove loading class
        postsList.classList.remove('loading');
      }
    };
    xhr.send();
  }

  // Rewrite current page URL according to current filters
  function rewriteURL() {

    var newUrl = basename;

    var nbParameters = 0;
    if(selects) {
      for (let select of selects) {
        if(select.value != '' && select.value != 'all'){
          nbParameters ++;
          newUrl += (nbParameters == 1) ? '?' : '&';
          newUrl += select.dataset.userslug + '=' + select.value;
        }
      }
    }
    if (search && search.value != ''){
      newUrl += (nbParameters > 0) ? '&' : '?';
      newUrl += 'recherche=' + search.value;
    }

    window.history.pushState("", "", newUrl);
  }

});
