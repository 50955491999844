/**
 * LazyLoading & Intersection Observer
 */

import lozad from 'lozad'

// Images
const defaultObserver = lozad('.lozad', {
  threshold: 0,
  loaded: function(el) {
    el.removeAttribute('data-src');
    el.removeAttribute('data-srcset');
  }
});
defaultObserver.observe();

// Sections
const sectionsObserver = lozad('[data-animated]', {
  threshold: 0.2
});

sectionsObserver.observe();
