window.addEventListener('load', function() {

  var calendarMonths = document.querySelector('.js-calendar__months');

  if(!calendarMonths)
    return;

  var months = calendarMonths.querySelectorAll('.month');
  var timelineItems = document.querySelectorAll('.timeline .timeline__item');

  months.forEach((month) => {
    month.addEventListener('click', function(){

      months.forEach((m) => {
        m.classList.remove('is_active');
      });
      month.classList.add('is_active');

      timelineItems.forEach((item) => {
        item.classList.remove('is_active');
      });
      document.querySelector('.timeline .timeline_item_'+month.dataset.id).classList.add('is_active');

    });
  });

});
