/**
 * All Sliders
 * based on Swiper 5
 * @url : https://swiperjs.com/api/
 */

import Swiper from 'swiper'

/**
* Page Standard Swiper
* Default + freemode
*/
// Default
const psSwiper = document.querySelectorAll('.js-swiper-page-standard')

if (psSwiper.length) {
  for (const swiper of psSwiper) {

    // Swiper instance
    const psSwiperInstance = new Swiper(swiper, {
      slidesPerView: 2,
      spaceBetween: 15,
      speed: 700,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        200: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 15,
        },
      }
      // init: false
    })

    // Init only if there is 2 or more slides
    const nbSlides = swiper.querySelectorAll('.swiper-slide')

    if (nbSlides.length > 1) {
      psSwiperInstance.init()
    }
  }
}

// Freemode
const swiperFreemode = document.querySelectorAll('.js-swiper-freemode')
if (swiperFreemode.length) {
  for (const swiper of swiperFreemode) {

    // Swiper instance
    const swiperFreeModeInstance = new Swiper(swiper, {
      slidesPerView: 'auto',
      centeredSlides: (Foundation.MediaQuery.current === 'small') ? false : true,
      grabCursor: true,
      freeMode: true,
      spaceBetween: (Foundation.MediaQuery.current === 'small') ? 15 : 30,
      speed: 700,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      init: false
    })

    // Init only if there is 2 or more slides
    const nbSlides = swiper.querySelectorAll('.swiper-slide')

    if (nbSlides.length > 1) {
      swiperFreeModeInstance.init()
    }
  }
}

